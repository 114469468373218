import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/modul-serwisowy.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    zdjecie2: file(relativePath: { eq: "crm-serwis-tlo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const modulserwisowy = ({ data }) => {
  return (
    <Artykul
      title="Dlaczego warto łączyć moduł serwisowy z&nbsp;systemem CRM?"
      articleImage={obrazekArtykulu}
      articleImageAlt="Dlaczego warto łączyć moduł serwisowy z systemem CRM?"
      metaTitle="Dlaczego warto łączyć moduł serwisowy z systemem CRM?"
      metaDescription="Moduł serwisowy połączony z systemem CRM ✅ W jaki celu warto połączyć systemy? • Jak usprawnisz swoją działalność? • Vlog 2022 • Zapytaj o demo!"
    >
        <Container className="container container--text">
        <p style={{
          paddingBottom: 20,
        }}>
        Prowadzenie działalności, która obejmuje jednocześnie sprzedaż i serwis stanowi niemałe 
        wyzwanie dla wielu właścicieli, menedżerów i kierowników. Obowiązkiem tych osób jest nie 
        tylko odpowiednie zaplanowanie prac poszczególnych zespołów, ale także zapewnienie sprawnej 
        współpracy międzyzespołowej. Okazuje się, że pojedyncze rozwiązania informatyczne nie są 
        wystarczające dla przedsiębiorstw zajmujących się różnymi obszarami. <strong>Kluczem do 
        sukcesu jest zintegrowanie systemów</strong>. Przeczytaj nasz artykuł albo obejrzyj vlog, 
        a&nbsp;dowiesz się <strong>dlaczego warto połączyć moduł serwisowy i CRM w jedno narzędzie.</strong>
        </p>
        <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="OTiwe-m4Zfw"
          title="Dlaczego WARTO łączyć CRM z systemem do obsługi ZGŁOSZEŃ?"
        />
      </div>

        </Container>
    <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Moduł serwisowy vs. moduł CRM</strong>
      </h2>
      <h3 style={{textAlign: "left"}}><strong>System do obsługi zgłoszeń – zarządzanie zleceniami</strong></h3>

      <p>
      Obszar zarządzania zleceniami wspierany jest przez moduł serwisowy – <strong>system, który 
      służy sprawnej obsłudze zgłoszeń z zakresu serwisów, przeglądów i instalacji</strong>. Osoby 
      odpowiedzialne za organizację pracy serwisantów, czyli właściciele, menedżerowie i&nbsp;kierownicy 
      otrzymują narzędzie, które pomaga efektywnie planować, koordynować oraz monitorować obowiązki.
      </p>
      <p>
      Codzienne zadania w przedsiębiorstwach serwisowych wykorzystujących program do zarządzania 
      zleceniami przebiegają sprawnie dzięki temu, że:
      </p>
      <p>
      <ul>
      <li>serwisanci mogą z&nbsp;łatwością sprawdzić szczegółowe informacje o&nbsp;klientach, urządzeniach i&nbsp;zleceniach,</li>
      <li>komunikacja między pracownikami, a&nbsp;także z&nbsp;klientami odbywa się za pomocą narzędzi systemowych,</li>
      <li>klienci mogą samodzielnie dokonywać zgłoszeń i&nbsp;sprawdzać szczegóły realizacji poprzez panel klienta,</li>
      <li>istnieje porządek w&nbsp;dokumentacji firmowej.</li>
      </ul>
      </p>
      </Container>

      <Container className="container container--text">

      <h3 style={{textAlign: "left"}}><strong>CRM – zarządzanie relacjami z&nbsp;klientami</strong></h3>

      <p>
      Drugi obszar, czyli zarządzanie relacjami z&nbsp;klientami jest wspierany przez system CRM – 
      rozwiązanie informatyczne, którego głównymi zadaniami są:
      </p>
      <p>
      <ul>
      <li>usprawnienie procesu pozyskiwania kontrahentów,</li>
      <li>uspójnienie obsługi i&nbsp;działań sprzedażowych,</li>
      <li>wsparcie procesu budowania silnych i&nbsp;trwałych relacji z&nbsp;klientami.</li>
      </ul>
      </p>
      <p>
      Istota systemu CRM jest ogromna ze względu na to, że jego funkcjonowanie skierowane jest 
      na klientów będących jednym z&nbsp;najważniejszych zasobów każdego przedsiębiorstwa. W zasadzie 
      można stwierdzić, że <strong>bez lojalnych kontrahentów żadna ze współczesnych firm nie 
      będzie w&nbsp;stanie działać w efektywny sposób</strong>. W&nbsp;związku z tym, wykorzystanie wsparcia 
      CRM-a w podejmowanych działaniach sprzedażowych to dzisiaj konieczność, a nie możliwość.
      </p>
      <p>
      Jeśli chcesz szerzej poznać opis funkcjonalności poszczególnych systemów – zapraszamy na 
      strony, które są im poświęcone – <Link to="https://synergiuscrm.pl"><strong>system CRM</strong></Link> i <Link to="https://serwisplanner.pl"><strong>program do obsługi zgłoszeń</strong></Link>.
      </p>
      </Container>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz usprawnić swoją działalność sprzedażową i&nbsp;serwisową?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;połączyć moduł serwisowy z&nbsp;CRM
            </Link>
          </div>
          </div>
    </section>
      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Łączenie – moduł serwisowy i&nbsp;CRM</strong>
      </h2>
      <p>
      Moduł serwisowy zintegrowany z modułem CRM to sposób na usprawnienie działań podejmowanych 
      przez Twoje przedsiębiorstwo. Dzięki takiemu rozwiązaniu <strong>komunikacja między Twoimi 
      pracownikami zostaje wzniesiona na wyższy poziom</strong> – ograniczona zostaje konieczność 
      ciągłych telefonów doprecyzowujących szczegóły współpracy z kontrahentem. Wszystkie niezbędne 
      informacje znajdują się w&nbsp;kompleksowym systemie, do którego dostęp mają zarówno handlowcy, 
      jak i serwisanci. Sprzedawcy odpowiedzialny za budowanie relacji z klientami z łatwością mogą 
      sprawdzić dane o realizowanym zleceniu (etap, podjęte działania, szczegóły realizacji) 
      i przekazać je zainteresowanemu kontrahentowi. W tym czasie serwisanci mogą zająć się swoimi, 
      z&nbsp;pewnością znacznie ważniejszymi obowiązkami.
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie2]}
        alts={["Dokumenty"]}
      />
      </Container>
      <Container className="container container--text">
      <p>
      Warto również oszczędzać czas samych handlowców, którzy w dużej mierze powinni skupiać się na 
      pozyskiwaniu nowych klientów i&nbsp;budowaniu relacji z tymi, których pozyskać się już udało. 
      Aby ograniczyć ich obowiązki warto wybrać <strong>program do obsługi zgłoszeń z&nbsp;wbudowanym 
      panelem klienta</strong>, który stwarza możliwość samodzielnego zarządzania zleceniami 
      i śledzenia ich szczegółów.
      </p>
      <p>
      Istotnym elementem zintegrowanego modułu serwisowego z CRM jest <strong>wspólny komunikator, 
      który pozwala wymieniać się wiadomościami o realizowanych zadaniach</strong>. Co więcej, 
      istnieje możliwość wiązania poszczególnych komunikatów ze zleceniami – sprawia to, że dostęp 
      do podejmowanych ustaleń jest szybszy i&nbsp;dużo prostszy. Podobne działanie można wykonać 
      z wiadomościami mailowymi.
      </p>
      <p>
      Ogromną zaletą połączenia systemów jest też to, że <strong>wszystkie informacje i narzędzia 
      znajdują się w&nbsp;jednym panelu</strong>. Oznacza to, że nie ma konieczności przelogowywania 
      i&nbsp;poszukiwania potrzebnych danych. Co więcej, praca poszczególnych pracowników jest 
      usprawniania poprzez zarządzanie uprawnieniami – handlowcy i serwisanci widzą tylko te 
      elementy, które są im niezbędne w&nbsp;codziennych działaniach. Jeden wspólny program nie 
      powoduje więc żadnego chaosu informacyjnego.
      </p>
      <p>
      Moduł serwisowy uzupełniony o system CRM to <strong>ogromna baza informacji, która podnosi 
      prowadzony biznes na wyższy poziom</strong>. Zarówno pracownicy działu handlowego, jak 
      i&nbsp;serwisowego otrzymują większą niż zazwyczaj liczbę danych – mogą opierać na nich swe 
      decyzje i z dużą większą dokładnością prowadzić działania. Zyskuje na tym proces planowania 
      harmonogramu prac, ofertowanie klienta czy też ocena rentowności współpracy.
      </p>
      </Container>
      
      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Podsumowanie</strong>
      </h2>
      <p>
      Moduł serwisowy połączony z systemem CRM to sposób na efektywną współpracę Twoich zespołów – 
      sprzedażowego i serwisowego. Dzięki połączeniu dwóch rozwiązań w jedno oszczędzisz czas 
      Twoich pracowników, a&nbsp;także dostarczysz im dużo nowych możliwości. Jeżeli chcesz wdrożyć 
      oba systemy w swoim przedsiębiorstwie, wybierz producenta, który w gamie swoich produktów 
      posiada te rozwiązania i jest w stanie je zintegrować. My jako Questy chętnie przedstawimy 
      Ci naszą ofertę i porozmawiamy o Twoich potrzebach – <Link to="/kontakt/"><strong>skontaktuj 
      się z nami</strong>.</Link>
      </p>
      </Container>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz usprawnić swoją działalność sprzedażową i&nbsp;serwisową?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;połączyć moduł serwisowy z&nbsp;CRM
            </Link>
          </div>
          </div>
    </section>       
      <p>
        <strong>Polecamy również:</strong>
        </p>
        <p>
        <ul>
          <li>
            <Link to="/analiza-przedwdrozeniowa/">Czym jest analiza przedwdrożeniowa? Dlaczego to must-have wdrożenia systemu dedykowanego?</Link>
          </li>
          <li>
            <Link to="/wsparcie-sprzedazy/">
              {" "}
              Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?
            </Link>
          </li>
          <li>
            <Link to="/7-wyzwan-w-serwisie/">
              {" "}
             7 problemów Twojego serwisu – jak je rozwiązać
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default modulserwisowy;
